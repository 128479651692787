(function () {
  'use strict';

  /* eslint-plugin-disable angular/johnpapa */

  /* eslint-disable camelcase */

  /**
   *
   * This file is included via the content page `search-bar-page`, because of its
   * depencency on the locale-specific variable `amzSearchApiHosts`, which is included
   * in that content page.
   *
   */
  //
  // TODO: Remove these polyfills after site polyfills are fixed
  //
  // Polyfills
  // ----------
  // Array.findIndex()
  Array.prototype.findIndex || Object.defineProperty(Array.prototype, 'findIndex', {
    value: function value(r) {
      if (null == this) throw new TypeError('"this" is null or not defined');
      var e = Object(this),
          t = e.length >>> 0;
      if ('function' != typeof r) throw new TypeError('predicate must be a function');

      for (var n = arguments[1], i = 0; i < t;) {
        var o = e[i];
        if (r.call(n, o, i, e)) return i;
        i += 1;
      }

      return -1;
    },
    configurable: !0,
    writable: !0
  }); // Array.find()

  Array.prototype.find || Object.defineProperty(Array.prototype, 'find', {
    value: function value(r) {
      if (null == this) throw new TypeError('"this" is null or not defined');
      var e = Object(this),
          t = e.length >>> 0;
      if ('function' != typeof r) throw new TypeError('predicate must be a function');

      for (var n = arguments[1], i = 0; i < t;) {
        var o = e[i];
        if (r.call(n, o, i, e)) return o;
        i += 1;
      }
    },
    configurable: !0,
    writable: !0
  }); // Array.includes()

  Array.prototype.includes || Object.defineProperty(Array.prototype, 'includes', {
    value: function value(r, e) {
      if (null == this) throw new TypeError('"this" is null or not defined');
      var t = Object(this),
          n = t.length >>> 0;
      if (0 === n) return !1;
      var i,
          o,
          a = 0 | e,
          u = Math.max(a >= 0 ? a : n - Math.abs(a), 0);

      for (; u < n;) {
        if ((i = t[u]) === (o = r) || 'number' == typeof i && 'number' == typeof o && isNaN(i) && isNaN(o)) return !0;
        u += 1;
      }

      return !1;
    }
  }); // force our Array.prototype.find polyfill for IE 11
  // this should be removed once the sites polyfill has been
  // updated

  if (!!window.MSInputMethodContext && !!document.documentMode) {
    Object.defineProperty(Array.prototype, 'find', {
      value: function value(r) {
        if (null == this) throw new TypeError('"this" is null or not defined');
        var t = Object(this),
            e = t.length >>> 0;
        if ('function' != typeof r) throw new TypeError('predicate must be a function');

        for (var n = arguments[1], o = 0; o < e;) {
          var i = t[o];
          if (r.call(n, i, o, t)) return i;
          o += 1;
        }
      }
    });
  }

  (function () {
    var wmUtil = {}; // ********************************
    //
    // Global Variables
    //
    // ********************************

    wmUtil.isMobileSite = window.isMobileSite || false;
    wmUtil.culture = window.PageClientData.UICulture;
    wmUtil.cultureKey = wmUtil.culture.toLowerCase().replace(/\-/, '_');
    wmUtil.cultureLang = wmUtil.cultureKey.substring(0, 2);
    wmUtil.priceType = window.customerPriceType.toLowerCase();
    wmUtil.swapPriceCultures = ['ja_jp', 'ko_kr', 'zh_cn', 'zh_hk', 'zh_my', 'zh_sg', 'zh_tw']; // Points regex
    // - Works for all cultures, except zh-CN, where there are no product points
    // - Assumes digits have already been converted to single-byte characters

    wmUtil.pointsRegex = /(\d+)\s*(point|punto|punkt|punt|點數|点数|포인트|ポイント|ポイント|ぽいんと|ぽいんと)(?:(s|e|y))?/i; // Includes de, en, es, fr, ja, ko, nl, pl, zh

    /**
     * File names for different document type cards
     */

    wmUtil.docTypeCards = {
      product: 'product-card-201905.html',
      video: 'video-card.html',
      'web page': 'web-page-card.html',
      document: 'document-card.html'
    }; //
    // Date to be used for backdating and product availability comparisons.
    // Set hours to 0,0,0,0 to ensure correct date comparison on last day of product availability

    wmUtil.today = PageClientData.ContentDate ? new Date(PageClientData.ContentDate.replace(/\-/g, '/').substring(0, 10)).setHours(0, 0, 0, 0) : new Date().setHours(0, 0, 0, 0); // ********************************
    //
    // Utility Functions
    //
    // ********************************

    /**
     * Checks whether user is backdated
     *
     * @return {boolean}
     */

    wmUtil.isBackdated = function () {
      var prevMonth = new Date();
      prevMonth.setDate(1);
      prevMonth.setHours(-1);
      return wmUtil.today <= prevMonth;
    };
    /**
     * Build search endpoint based on whether
     * using a production or preproduction host
     */


    var searchApiHosts = window.amzSearchApiHosts || {}; // formerly `apiHosts`

    var preProdRegex = /^web|qa|ua|st/i;
    var apiHostname = preProdRegex.test(window.location.host) ? searchApiHosts.preProd : searchApiHosts.prod;
    var apiProductHostname = preProdRegex.test(window.location.host) // capture country code and use in product search endpoint
    ? searchApiHosts.preProd.replace(/api-(\w{2})/i, 'https://dep$1') : searchApiHosts.prod.replace(/api-(\w{2})/i, 'https://dep$1-');
    wmUtil.searchEndpoint = 'https://' + apiHostname + '.melaleuca.com/search/v3';
    /*
    QA:   https://depus-qaapi.melaleuca.com
    DEV:  https://depus-dvapi.melaleuca.com
    UA:   https://depus-uaapi.melaleuca.com
    Prod: https://depus-api.melaleuca.com
    */

    wmUtil.searchProductsEndpoint = apiProductHostname + 'api.melaleuca.com/search/suggestions';
    console.log(wmUtil.searchProductsEndpoint);
    wmUtil.searchIndex = wmUtil.isBackdated() ? wmUtil.cultureKey + '_backdate' : wmUtil.cultureKey;
    /**
     * Determines whether product is has a current availability date
     *
     * @param  {Object}  product - All product fields from Elasticsearch
     * @return {Boolean}         - True if current date is between
     *                             start and end dates, else false
     */

    wmUtil.isAvailable = function (product) {
      // Get offset in hours and create offset string (e.g. `-06:00`)
      var offset = new Date().getTimezoneOffset() / 60;
      var offsetHrs = parseInt(offset);
      var offsetMin = Math.abs(offset) % 1 === 0.5 ? '30' : '00';
      var offsetString = "".concat(offsetHrs > 0 ? '-' : '+').concat(Math.abs(offsetHrs) <= 9 ? "0".concat(Math.abs(offsetHrs), ":").concat(offsetMin) : "".concat(Math.abs(offsetHrs), ":").concat(offsetMin));
      var startDate = new Date("".concat(product.date.start).concat(offsetString)).setHours(0, 0, 0, 0); // Set hours to 0,0,0,0 to ensure correct date comparison on last day of product availability

      var endDate = new Date("".concat(product.date.end).concat(offsetString)).setHours(0, 0, 0, 0);
      return wmUtil.today >= startDate && wmUtil.today <= endDate;
    };
    /**
     * Formats time for displaying on video thumbnail
     *
     * @param  {string} timeInSec - Time in seconds
     * @return {string}           - Time formatted as h:mm:ss or m:ss
     */


    wmUtil.formatSeconds = function (timeInSec) {
      timeInSec = Number(timeInSec);
      var hours = Math.floor(timeInSec / 3600);
      var minutes = Math.floor(timeInSec % 3600 / 60);
      var seconds = Math.floor(timeInSec % 3600 % 60);
      var formattedHours = hours > 0 ? hours + ':' : '';
      var formattedMinutes = hours > 0 ? ('0' + minutes).slice(-2) : minutes;
      var formattedSeconds = ':' + ('0' + seconds).slice(-2);
      return formattedHours + formattedMinutes + formattedSeconds;
    };

    wmUtil.getParamByName = function (name) {
      var url = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : window.location.href;
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
      var results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    };
    /**
     * Checks whether an object is defined
     *
     * @param  {Any} object - Any type of object
     * @return {Boolean}      Indicates whether given object is defined
     */


    wmUtil.isDefined = function (object) {
      return typeof object !== 'undefined';
    };
    /**
     * Formats number used to indicate size/quantity of product
     *
     * @param  {string} number - Value for product size/quantity
     * @return {number}        - Formatted size/quantity value
     */


    wmUtil.formatSize = function (number) {
      if (number % 1 === 0) {
        return parseInt(number);
      }

      return parseFloat(number);
    };

    wmUtil.getWebUrl = function (url) {
      if (/.*?melaleuca\.(com|info).*?\//.test(url)) {
        return url.replace(/.*?melaleuca\.(com|info).*?\//, window.location.origin + '/');
      }

      return window.location.protocol + '//' + url;
    };
    /**
     * Replaces double-byte numbers in search term with single-byte equivalent
     * @param  {String} searchTerm - Search term typed by user, which may contain double-byte numbers
     * @return {String}            - Search term, with possible replacement of double-byte numbers
     */


    wmUtil.replaceFullWidthNumerals = function (searchTerm) {
      return searchTerm.replace(/[\uFF10-\uFF19]/g, function (numChar) {
        return String.fromCharCode(numChar.charCodeAt() - 0xfee0);
      });
    };
    /**
     * Mapping for product field names to simplify usage and create
     * abstraction of the API response data structure
     *
     * @param  {Object} product - Raw data structure from search API
     * @return {Object}         - Simplified product data structure
     */


    wmUtil.mapProductFields = function (product) {
      var fields = {};

      try {
        fields = {
          points: product.points,
          sku: product.sku,
          accessories: product.accessories || [],
          off_sale_title: product.off_sale_title || window.searchCultureConfig.soldOut,
          is_inventory_controlled: product.is_inventory_controlled,
          is_available: wmUtil.isAvailable(product),
          is_kit: product.kit_type_id === 3
        };

        if (product.hasOwnProperty('name')) {
          fields.name_enhanced = product.name.enhanced;
        }

        if (product.hasOwnProperty('size')) {
          fields.size = product.size.value;
          fields.size_type = product.size.type;
        }

        if (product.hasOwnProperty('price')) {
          fields.price_primary = product.price[wmUtil.priceType];
          fields.price_secondary = product.price.retail; // Special conditions for China

          if (wmUtil.cultureKey === 'zh_cn') {
            fields.points = null; // No points in China

            fields.is_commissionable = product.is_commissionable;
          }
        }

        if (product.hasOwnProperty('image')) {
          fields.image = product.image.regular;
        }

        if (product.hasOwnProperty('categories')) {
          fields.is_limited_time_offer = product.categories.some(function (item) {
            return item.id === 88;
          });
        }
      } catch (error) {
        console.log('error mapping product fields', product, error);
      }

      return fields;
    };
    /**
     * Mapping for video field names
     *
     * @param  {Object} video - Raw data structure from search API
     * @return {Object}       - Simplified video data structure
     */


    wmUtil.mapVideoFields = function (video) {
      var fields = {};

      try {
        fields = {
          media_id: video.media_id,
          title: video.title,
          thumbnail: video.thumbnail,
          duration: wmUtil.formatSeconds(video.duration)
        };

        if (video.hasOwnProperty('description')) {
          fields.description = video.description.short;
        }
      } catch (error) {
        console.log('error mapping video fields', video, error);
      }

      return fields;
    };
    /**
     * Mapping for web page field names
     *
     * @param  {Object} webPage - Raw data structure from search API
     * @return {Object}         - Simplified web page data structure
     */


    wmUtil.mapWebPageFields = function (webPage) {
      var fields = {};

      try {
        fields = {
          url: wmUtil.getWebUrl(webPage.url),
          title: webPage.title,
          thumbnail: webPage.image_txt
        };

        if (webPage.hasOwnProperty('description')) {
          fields.description = webPage.description.short;
        }
      } catch (error) {
        console.log('error mapping web page fields', webPage, error);
      }

      return fields;
    };
    /**
     * Mapping for document field names
     *
     * @param  {Object} doc - Raw data structure from search API
     * @return {Object}     - Simplified document data structure
     */


    wmUtil.mapDocumentFields = function (doc) {
      var fields = {};

      try {
        fields = {
          url: doc.cdn_path,
          title: doc.title,
          thumbnail: doc.image
        };

        if (doc.hasOwnProperty('description')) {
          fields.description = doc.description.short;
        }
      } catch (error) {
        console.log('error mapping document fields', doc, error);
      }

      return fields;
    };
    /**
     * Determines which type of mapping to perform, then calls mapping function
     *
     * @param  {Object} product - Raw data structure from search API
     * @return {Object}         - Simplified data structure
     */


    wmUtil.formatFields = function (item) {
      var docType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'product';

      if (docType === 'product') {
        return wmUtil.mapProductFields(item);
      } else if (docType === 'video') {
        return wmUtil.mapVideoFields(item);
      } else if (docType === 'web page') {
        return wmUtil.mapWebPageFields(item);
      } else if (docType === 'document') {
        return wmUtil.mapDocumentFields(item);
      }
    };
    /**
     * Sorts list of products to move unavailable items to the bottom
     *
     * @param  {Array} products - Array of products to be sorted
     * @return {Array}            Array of sorted products
     */


    wmUtil.sortAvailable = function (products) {
      var sortBy = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'is_available';
      var available = products.filter(function (product) {
        return product[sortBy];
      });
      var unavailable = products.filter(function (product) {
        return !product[sortBy];
      });
      return available.concat(unavailable);
    };
    /**
     * Fetches video data from media API and adds to `window.videoInfo`
     * for use by MelaVideoPlayer.
     *
     * @param  {Array} videoList - List of video media IDs
     */


    wmUtil.setVideoData = function (videoList) {
      window.videoInfo = window.videoInfo || {};
      videoList.forEach(function (mediaId) {
        $.ajax({
          type: 'GET',
          url: '/ExternalServices/Api/Media/',
          data: {
            mediaItemName: mediaId,
            culture: wmUtil.culture
          }
        }).success(function (data) {
          window.videoInfo[mediaId] = data;
        }).error(function (error) {
          console.log('Unable to get video data for', mediaId, error);
        });
      });
    }; // Attach to window for global usage


    window.wmUtil = wmUtil; // ********************************
    //
    // AngularJS Utilities
    //
    // ********************************

    var ngWmUtil = angular.module('ngWmUtil', []);
    ngWmUtil.config(['$provide', function ($provide) {
      /**
       * Overloads AngularJS `currency` filter to handle special cases
       */
      $provide.decorator('currencyFilter', ['$delegate', '$filter', function ($delegate, $filter) {
        var srcFilter = $delegate;

        function extendedFilter() {
          if (wmUtil.cultureKey === 'ko_kr') {
            return "".concat($filter('number')(arguments[0], 0), "\uC6D0");
          } else if (wmUtil.cultureKey === 'ja_jp') {
            return "\xA5".concat($filter('number')(arguments[0], 0));
          } else if (wmUtil.cultureKey === 'zh_hk') {
            return "HK$".concat($filter('number')(arguments[0], 1));
          } else if (wmUtil.cultureKey === 'zh_tw') {
            return "NT$".concat($filter('number')(arguments[0], 0));
          }

          return srcFilter.apply(this, arguments);
        }

        return extendedFilter;
      }]);
    }]);
    /**
     * Filter for trusting a string as HTML via $sce
     */

    ngWmUtil.filter('trustAsHtml', ['$sce', function ($sce) {
      return function (value) {
        return $sce.trustAsHtml(value);
      };
    }]);
    /**
     * Filter for converting number to absolute value
     */

    ngWmUtil.filter('abs', [function () {
      return function (value) {
        return Math.abs(value);
      };
    }]);
    ngWmUtil.service('dataService', ['$http', '$window', '$sce', function ($http, $window, $sce) {
      var self = {};
      /**
       * Fetches search results from Elasticsearch via API Gateway
       *
       * @param  {Object} params - template ID, search index, template params
       * @return {Object}          AngularJS $http promise
       */

      self.getSearchResults = function (params) {
        // Backdating stuff
        if ($window.hasOwnProperty('PageClientData') && $window.PageClientData.BackdateHash && $window.PageClientData.ContentDate) {
          params.backdateHash = $window.PageClientData.BackdateHash;
          params.contentDate = $window.PageClientData.ContentDate;
        }

        if (params.id === 'suggestions') {
          // {{dep_api_url}}/search/suggestions?searchterm=soap&culture=en-US
          return $http({
            method: 'GET',
            url: wmUtil.searchProductsEndpoint + '?searchterm=' + params.params.searchTerm + '&culture=' + wmUtil.culture
          });
        } else {
          return $http({
            method: 'GET',
            url: wmUtil.searchEndpoint,
            params: params
          });
        }
      };
      /**
       * Checks inventory-controlled SKUs for availability via
       * the inventory control API and updates the availability flag
       * and off-sale title using the response.
       *
       * @param  {Array}  skuList        - Array of inventory-controlled SKUs
       * @param  {Array}  productsArray  - Array of products from Angular $scope
       * @param  {Object} $scope         - Angular $scope object, to enable applying changes to products array
       * @return {Object}                - Promise
       */


      self.checkInventory = function (skuList, productsArray, $scope) {
        if (skuList.length) {
          return $window.productInventory.getInventorySearchResults(skuList).then(function (data) {
            return data.map(function (skuDetails) {
              var index = productsArray.indexOf(productsArray.find(function (product) {
                return product.sku === skuDetails.sku;
              }));
              $scope.$apply(function () {
                productsArray[index].is_available = skuDetails.isQuantityAvailable;
                productsArray[index].off_sale_title = skuDetails.offSaleTitle === null || skuDetails.offSaleTitle === '' ? $window.searchCultureConfig.soldOut : skuDetails.offSaleTitle;
              });
            });
          }, function (err) {
            console.info("Unable to check inventory status for \"".concat(skuList.join(', '), "\""));
          });
        }

        return [];
      };

      self.getTemplateUrl = function () {
        var docType = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'product';
        return $sce.trustAsResourceUrl("https://cdn.melaleuca.com/html/product-store/".concat(wmUtil.docTypeCards[docType]));
      };

      return self;
    }]);
  })();

}());
